import logo from './logo.svg';
import './App.css';
import ComingSoon from './pages/Comingsoon';

function App() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}

export default App;
